import React, {FC, useRef, useState, useEffect} from 'react';
import cloudUploadIcon from '../../../assets/images/upload1.svg';
import uploadIcon from '../../../assets/images/upload.svg';
import APIService from '../../../network/APIService';
import {UserDAO} from '../../../network/user';
import GraduationData from "../GraduationData";
import PreSignedUrl from "../PreSignedUrl";
import uploadStudentList from "./index";
import moment from "moment";

interface GraduationDetailsProps {
    activeTab: string,
    onTabClick: (tabId: string) => void,
    graduationData: GraduationData
}

const UploadStudentList: FC<GraduationDetailsProps> = ({activeTab, onTabClick, graduationData}) => {
        const fileInputRef = useRef<HTMLInputElement>(null);
        const uploadFormRef = useRef<HTMLFormElement>(null);
        // const [selectedFile, setSelectedFile] = useState<File | null>(null);
        const [isUploadComplete, setUploadComplete] = useState(false);
        const [loading, setLoading] = useState<boolean>(false);
        const [list, setList] = useState<any>(null);
        const [gdata, setGraduationData] = useState<GraduationData>(graduationData);
        const [downloadStudentList, setDownloadStudentList] = useState<any>(null);

        const handleUploadClick = (): void => {
            if (fileInputRef.current) {
                fileInputRef.current.click();
            }
        };

        const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();
            event.stopPropagation();
            console.log('object, event', event);
            const file = event.target.files && event.target.files[0];
            if (file) {
                setLoading(true)
                uploadFile(file).catch(console.error);
                setUploadComplete(true);
                setLoading(false);

            }
        }

        const uploadFile = async (file: File) => {
            if (!graduationData.id) {
                return;
            }
            const response: PreSignedUrl = await APIService.getStudentListUploadUrl(graduationData.id, file.name);
            console.log('object, response', response);
            if (response.url) {
                APIService.uploadStudentList(response, file).then(() => {
                    graduationData.studentListFilename = file.name;
                    graduationData.studentListLastUpdated = new Date();
                    setGraduationData(graduationData);
                });
            }
        }

        const fetchList = async () => {
            try {
                const list = await UserDAO.getList();
                setList(list.list.name);
            } catch (error) {
            }
        };

        const downloadStudentsFile = async () => {
            if (graduationData.id) {
                const response = await APIService.getStudentListDownloadUrl(graduationData.id);
                console.log('object, response', response);
                if (response.url) {
                    // Create a download link
                    const downloadLink = document.createElement('a');
                    downloadLink.href = response.url;
                    downloadLink.download = "download";
                    document.body.appendChild(downloadLink);
                    // Trigger the click event to start the download
                    downloadLink.click();
                    // Remove the link from the DOM
                    document.body.removeChild(downloadLink);
                }
            }

        }

        useEffect(() => {
            setUploadComplete(false);
            fetchList();
            fetchStudentList();
        }, []);


        const fetchStudentList = async () => {
            try {
                const response = await APIService.getUploadedStudentList();
                console.log('object, response....', response);
                if (response.status === 200) {
                    setLoading(false);
                    setDownloadStudentList(response.data.url);
                } else {
                    setLoading(false);
                }
                console.log(response);
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        };

        const downloadFile = async (path: string, name: string) => {
            try {
                const response = await fetch(path);
                const blob = await response.blob();

                // Create a download link
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = name;
                document.body.appendChild(downloadLink);

                // Trigger the click event to start the download
                downloadLink.click();

                // Remove the link from the DOM
                document.body.removeChild(downloadLink);
            } catch (error) {
                console.error('Error downloading video:', error);
            }
        };

        return (
            <div>
                <div className="py-10">
                    <h1 className="text-xl font-bold m-0 leading-normal tracking-normal border-b-2 border-tertiary">
                        Upload Student List
                    </h1>
                    <div className="grid grid-cols-3 mt-6">
                        <div className="col-span-2">
                            <p>Upload the student list containing the relevant fields in .csv format</p>
                        </div>
                        <div className="flex flex-col items-center h-[10rem]">
                            <div>
                                <input
                                    type="file"
                                    id="fileInput"
                                    accept=".csv"
                                    className="hidden"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                                <button
                                    className="bg-primary text-white font-bold py-2 px-4 rounded inline-flex items-center gap-3"
                                    onClick={handleUploadClick}
                                    disabled={loading}
                                >
                                    <span>{loading ? 'Uploading' : 'Upload'}</span>
                                    <img src={uploadIcon} alt="upload icon"/>
                                </button>
                            </div>
                        </div>

                    </div>
                    {isUploadComplete && (
                        <div className="grid grid-cols-3 mt-6">
                            <div className="col-span-3">
                                <p>Students list upload complete. You may re-upload a new version anytime.</p>
                            </div>
                        </div>
                    )}
                    {gdata.studentListFilename && (
                    <div
                        className="border-b-2 border-tertiary "
                        style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}
                    >
                        <p className="font-bold text-primary pb-2">Uploaded Student List:</p>
                        <p className="">{gdata.studentListFilename}
                            (uploaded: {moment(gdata.studentListLastUpdated).format('LLL')})</p>
                        <button
                            type="button"
                            className="inline-flex items-center py-2 px-2 text-sm font-medium text-center text-gray-900 bg-white border border-black rounded-lg"
                            style={{opacity: 1}}
                            onClick={() => {
                                downloadStudentsFile();
                            }}
                        >
                            Download
                        </button>
                    </div>
                    )}

                </div>
                <div className="flex justify-center align-center py-8">
                    <button
                        type="submit"
                        className={`bg-primary text-white font-bold py-2 px-4 rounded-lg inline-flex items-center gap-3`}
                        onClick={() => onTabClick('page3')}
                        disabled={loading}
                    >
                        <span>Continue</span>
                    </button>
                </div>
            </div>
        );
    }
;

export default UploadStudentList;
