import { FC } from 'react';
import Burger from '../Burger';
import styles from './Header.module.scss';

const Header: FC = () => {
  return (
    <header className={styles.header}>
      <Burger />
    </header>
  );
};

export default Header;
