import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as urls from '../../routePaths';
import APIService from '../../network/APIService';
import CircularProgressWithLabel from '../CircularProgressWithLabel';

const SignUpForm: React.FC = ({}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [signupInfo, setSignupInfo] = useState<any>({
    firstName: '',
    lastName: '',
    institutionName: '',
    position: '',
    department: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleCheckboxChange = (): void => {
    setIsChecked((prev: boolean) => !prev);
  };

  const handleRedirect = (): void => {
    navigate(urls.LOGIN);
  };

  const handleSignUp = async () => {
    setLoading(true);
    try {
      const response = await APIService.signup(signupInfo);
      console.log('object, response', response);
      if (response.status === 201) {
        setLoading(false);
        handleRedirect();
      } else {
        alert(response.response.data);
        setLoading(false);
      }
      console.log(response);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const validateForm = () => {
    // event.preventDefault();
    if (
      signupInfo.firstName === '' ||
      signupInfo.lastName === '' ||
      signupInfo.institutionName === '' ||
      signupInfo.position === '' ||
      signupInfo.department === '' ||
      signupInfo.email === '' ||
      signupInfo.password === '' ||
      signupInfo.confirmPassword === ''
    ) {
      alert('Please fill all the fields');
    } else if (signupInfo.password !== signupInfo.confirmPassword) {
      alert('Password and Confirm Password do not match');
    } else if (isChecked === false) {
      alert('Please accept the terms and conditions');
    } else {
      handleSignUp();
    }
  };

  return (
    <div>
      <div className="max-w-sm mx-auto sm:max-w-md sm:flex flex-col items-center">
        <h1 className="font-bold text-primary text-4xl ml-4">SIGN UP</h1>
        <p className="text-gray mt-2 mb-2 lg:mb-4 text-sm lg:text-base">
          Create your account by filling the form below
        </p>
        <div className="flex gap-6">
          <input
            type="text"
            id="firstname"
            placeholder="FIRST NAME *"
            className="w-1/2 mb-1 lg:mb-3 text-sm text-primary placeholder-primary"
            value={signupInfo.firstName}
            onChange={(e) => {
              setSignupInfo({ ...signupInfo, firstName: e.target.value });
            }}
          />
          <input
            type="text"
            id="lastname"
            placeholder="LAST NAME *"
            className="w-1/2 mb-1 lg:mb-3 text-sm text-primary placeholder-primary"
            value={signupInfo.lastName}
            onChange={(e) => {
              setSignupInfo({ ...signupInfo, lastName: e.target.value });
            }}
          />
        </div>
        <input
          type="text"
          id="institutionName"
          placeholder="NAME OF INSTITUTION *"
          className="w-full mb-1 lg:mb-3 text-sm text-primary placeholder-primary"
          value={signupInfo.institutionName}
          onChange={(e) => {
            setSignupInfo({ ...signupInfo, institutionName: e.target.value });
          }}
        />
        <div className="flex gap-6">
          <input
            type="text"
            id="position"
            placeholder="POSITION *"
            className="w-1/2 mb-1 lg:mb-3 text-sm text-primary placeholder-primary"
            value={signupInfo.position}
            onChange={(e) => {
              setSignupInfo({ ...signupInfo, position: e.target.value });
            }}
          />
          <input
            type="text"
            id="department"
            placeholder="DEPARTMENT*"
            className="w-1/2 mb-1 lg:mb-3 text-sm text-primary placeholder-primary"
            value={signupInfo.department}
            onChange={(e) => {
              setSignupInfo({ ...signupInfo, department: e.target.value });
            }}
          />
        </div>
        <input
          type="email"
          id="email"
          placeholder="EMAIL ADDRESS *"
          className="w-full mb-1 lg:mb-3 text-sm text-primary placeholder-primary"
          value={signupInfo.email}
          onChange={(e) => {
            setSignupInfo({ ...signupInfo, email: e.target.value });
          }}
        />
        <div className="flex gap-6">
          <input
            type="password"
            id="password"
            placeholder="PASSWORD *"
            className="w-1/2 mb-3 text-sm text-primary placeholder-primary"
            value={signupInfo.password}
            onChange={(e) => {
              setSignupInfo({ ...signupInfo, password: e.target.value });
            }}
          />
          <input
            type="password"
            id="condfirmPassword"
            placeholder="CONFIRM PASSWORD *"
            className="w-1/2 mb-3 text-sm text-primary placeholder-primary"
            value={signupInfo.confirmPassword}
            onChange={(e) => {
              setSignupInfo({ ...signupInfo, confirmPassword: e.target.value });
            }}
          />
        </div>
        <div className="flex items-center mb-1 lg:mb-4">
          <input
            id="default-checkbox"
            type="checkbox"
            value=""
            className="text-primary focus:ring-2 focus:ring-gray"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="default-checkbox" className="ml-2 text-xs font-bold text-primary">
            ACCEPT THE TERMS AND CONDITIONS
          </label>
        </div>
        <div className="w-full flex flex-col justify-center items-center">
          <button
            className={`bg-${isChecked ? 'primary' : 'ash'} text-base text-white font-medium px-5 py-1 lg:px-10 lg:py-2 rounded-md ${isChecked ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            disabled={!isChecked}
            onClick={() => (isChecked ? validateForm() : null)}
          >
            {loading ? <CircularProgressWithLabel /> : 'Sign Up'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
