import { FC } from 'react';
import { useEffect } from 'react';

const ContactForm: FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @TS-ignore
      if ((window as any).hbspt) {
        // @TS-ignore
        (window as any).hbspt.forms.create({
          portalId: '45295564',
          formId: '211cf1b1-9547-4850-99ac-4de1a524754c',
          target: '#hubspotForm',
        });
      }
    });
  }, []);

  return (
    <div>
      <div id="hubspotForm"></div>
    </div>
  );
};

export default ContactForm;
