import { FC, useEffect, useRef, useState, ChangeEvent } from 'react';
import cloudUploadIcon from '../../assets/images/upload1.svg';
import APIService from '../../network/APIService';

const BrandKit: FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRefLogo = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [lowerThirdUploadedImage, setLowerThirdUploadedImage] = useState<File | null>(null);
  const [logo, setLogo] = useState<string>('');
  const [lowerThird, setLowerThird] = useState<string>('');
  const [font, setFont] = useState<string>('');
  const [selectedColor, setSelectedColor] = useState<string>('#000000');

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    setLoading(true);
    try {
      const response = await APIService.getAssets();
      console.log('response', response);
      if (response.status === 200) {
        setLoading(false);
        setLogo(response.data.logo);
        setLowerThird(response.data.lowerThird);
        setSelectedColor(response.data.color);
        setFont(response.data.font);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleUploadClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUploadClickLogo = (): void => {
    if (fileInputRefLogo.current) {
      fileInputRefLogo.current.click();
    }
  };

  const handleFontChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFont(e.target.value);
    handleUploadBranding(e.target.value, 'font');
    // handleFileChange(e.target.value, 'color');
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>, type: string) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (type === 'logo') setUploadedImage(selectedFile);
    if (type === 'lower-third') setLowerThirdUploadedImage(selectedFile);
    try {
      const response = await APIService.uploadAssets(type, selectedFile);
      console.log('object, response', response);
      if (response.status === 200) {
        setLoading(false);
        setLogo(response.data.logo);
      } else {
        // alert(response.response.data);
        setLoading(false);
      }
      console.log(response);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleUploadBranding = async (_: string, type: string) => {
    try {
      const obj = {
        name: _,
      };
      const response = await APIService.uploadBranding(type, obj);
      console.log('object, response', response);
      if (response.status === 202) {
        setLoading(false);
        // alert('Branding uploaded successfully');
      } else {
        setLoading(false);
      }
      console.log(response);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleColorChange = (event: any) => {};
  //   {
  //     "font": "Times",
  //     "color": "#000000",
  //     "logo": "https://gradcut-graduations-test.s3-accelerate.amazonaws.com/TENANT%23arslankhalid889-gmail-com/assets/logo.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6GBMDJN6KE3MSLAF%2F20240218%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240218T080803Z&X-Amz-Expires=3600&X-Amz-Signature=4d923f4b9e4f72a42e45b5aa4cefa181c213c156c555acac89926a70d49d63b8&X-Amz-SignedHeaders=host",
  //     "lowerThird": "https://gradcut-graduations-test.s3-accelerate.amazonaws.com/TENANT%23arslankhalid889-gmail-com/assets/lower-third.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA6GBMDJN6KE3MSLAF%2F20240218%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240218T080803Z&X-Amz-Expires=3600&X-Amz-Signature=86f0b80a05d28a40627cae1294d1996d15fe23dd086d61aaa876514f46c1a4e2&X-Amz-SignedHeaders=host"
  // }
  return (
    <div>
      <h1 className="text-3xl font-bold m-0 leading-relaxed tracking-wide">Brand Kit</h1>
      <div className="pt-4">
        <h1 className="text-xl font-bold m-0 leading-normal tracking-normal border-b-2 border-tertiary">
          Overlays
        </h1>
        <div className="grid grid-cols-2 py-3">
          <div>
            <p className="font-bold">Logo</p>
            <p className="text-gray text-sm pt-2">Upload Logo</p>

            <div onClick={handleUploadClickLogo}>
              {uploadedImage && (
                <div className="bg-gallery w-48 h-40 my-5 flex items-center justify-center">
                  <div
                    className={
                      uploadedImage != null
                        ? ' flex justify-center items-center w-100 h-40'
                        : 'bg-catalyst rounded-full flex justify-center items-center w-14 h-14'
                    }
                  >
                    <img
                      src={
                        uploadedImage != null ? URL.createObjectURL(uploadedImage) : cloudUploadIcon
                      }
                      alt="Uploaded logo"
                    />
                    <input
                      type="file"
                      ref={fileInputRefLogo}
                      className="hidden"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => handleFileChange(e, 'logo')}
                    />
                  </div>
                </div>
              )}
              {!uploadedImage && (
                <div className="bg-gallery w-48 h-40 my-5 flex items-center justify-center">
                  {/* <div className="bg-catalyst rounded-full flex justify-center items-center w-14 h-14"> */}
                  <img src={logo} className="w-100 h-40" />
                  <input
                    type="file"
                    ref={fileInputRefLogo}
                    className="hidden"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => handleFileChange(e, 'logo')}
                  />
                  {/* </div> */}
                </div>
              )}
            </div>
          </div>
          <div>
            <p className="font-bold">Lower Third</p>
            <p className="text-gray text-sm pt-2">Upload Lower Third</p>
            <div onClick={handleUploadClick}>
              {lowerThirdUploadedImage && (
                <div className="bg-gallery w-48 h-40 my-5 flex items-center justify-center">
                  <div
                    className={
                      lowerThirdUploadedImage != null
                        ? ' flex justify-center items-center w-100 h-40'
                        : 'bg-catalyst rounded-full flex justify-center items-center w-14 h-14'
                    }
                  >
                    <img
                      src={
                        lowerThirdUploadedImage != null
                          ? URL.createObjectURL(lowerThirdUploadedImage)
                          : cloudUploadIcon
                      }
                      alt="Uploaded logo"
                    />
                    <input
                      type="file"
                      ref={fileInputRef}
                      className="hidden"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => handleFileChange(e, 'lower-third')}
                    />
                  </div>
                </div>
              )}
              {!lowerThirdUploadedImage && (
                <div className="bg-gallery w-48 h-40 my-5 flex items-center justify-center">
                  {/* <div className="bg-catalyst rounded-full flex justify-center items-center w-14 h-14"> */}
                  <img src={lowerThird} className="w-100 h-40" />
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => handleFileChange(e, 'lower-third')}
                  />
                  {/* </div> */}
                </div>
              )}
            </div>
            {/* <div className="bg-gallery w-48 h-40 my-5 flex items-center justify-center">
              <div className="bg-catalyst rounded-full flex justify-center items-center w-14 h-14">
                <img src={cloudUploadIcon} alt="cloud upload icon" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="pt-4">
        <h1 className="text-xl font-bold m-0 leading-normal tracking-normal border-b-2 border-tertiary">
          Branding
        </h1>
        <div className="grid grid-cols-2 py-3">
          <div>
            <p className="font-bold">Color</p>
            <p className="text-gray text-sm pt-2">Select color</p>
            {/* <div className="bg-gallery my-5 rounded-full w-32 h-32"></div> */}
            {/* <div className="flex border border-gray text-gray-900 text-sm rounded-lg w-40">
              <select
                id="colors"
                className="bg-white border border-r border-gray text-gray-900 text-sm rounded-lg block w-20 p-2.5"
              >
                <option value="HEX">HEX</option>
                <option value="RGB">RGB</option>
                <option value="HSL">HSL</option>
              </select>
              <input
                type="text"
                id="small-input"
                placeholder="#EDEDED"
                className="block w-full p-2 text-gray-900 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div> */}
            <div>
              <input
                type="color"
                id="colorpicker"
                style={{
                  border: 'none',
                  background: 'none',
                  width: '25px',
                  height: '25px',
                }}
                value={selectedColor}
                onChange={(event) => {
                  setSelectedColor(event.target.value);
                  handleUploadBranding(event.target.value, 'color');
                }}
              />
              <input
                id="standard-basic"
                // variant="standard"
                style={{ width: '100px', padding: '0 10px' }}
                value={selectedColor}
                onChange={(event) => {
                  setSelectedColor(event.target.value);
                  handleUploadBranding(event.target.value, 'color');
                }}
              />
              {/* <img src={colorIcon} alt="color-picker" /> */}
            </div>
          </div>
          <div>
            <p className="font-bold">Font</p>
            <p className="text-gray text-sm pt-2">Select Font</p>
            {/* <div className="bg-gallery w-48 h-40 my-5 flex items-center justify-center">
              <h1>FONT PREVIEW</h1>
            </div> */}
            <div>
              <select
                id="colors"
                className="bg-white border border-r border-gray text-gray-900 text-sm rounded-lg block w-48 p-2.5"
                value={font}
                onChange={(e) => handleFontChange(e)}
              >
                <option value="Poppins">Poppins</option>
                <option value="Roboto">Roboto</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandKit;
