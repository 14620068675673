import React, { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface Props {
  children: React.ReactNode | any;
}
const ProtectedArea: FC<Props> = ({ children }) => {
  let location = useLocation();
  const token = localStorage.getItem('token');
  if (token === null) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedArea;
