import { FC } from 'react';
import QRCode from 'react-qr-code';
import copyIcon from '../../../assets/images/content_copy.svg';
import CircularProgressWithLabel from '../../../components/CircularProgressWithLabel';

interface GraduationDetailsProps {
  activeTab: string;
  loading?: boolean;
  graduationData: GraduationData;
  graduationList: any;
  onTabClick: (tabId: string) => void;
  onClickFinish: () => void;
}

interface GraduationData {
  year?: number | null;
  semester?: string;
  faculty?: string;
  institution?: string;
  degree?: string;
  graduationDate?: string;
  date?: string;
}

const QrCode: FC<GraduationDetailsProps> = ({
  activeTab,
  onTabClick,
  graduationList,
  graduationData,
  onClickFinish,
  loading,
}) => {
  return (
    <div>
      <div className="grid grid-cols-2 py-3">
        {/* Graduation Details */}
        <div className="block max-w-sm p-6 bg-white border border-ash rounded-lg shadow hover:bg-gallery">
          <h1 className="flex justify-center text-xl text-primary font-bold m-0 pb-2 leading-normal tracking-normal">
            Graduation Details
          </h1>
          <div className=" border-b border-tertiary">
            <div className="grid grid-cols-2 py-2">
              <p className="font-bold text-primary">Year:</p>
              <div className="flex items-center">
                <p className="font-bold">{graduationData.year}</p>
              </div>
            </div>
          </div>
          <div className=" border-b border-tertiary">
            <div className="grid grid-cols-2 py-2">
              <p className="font-bold text-primary">Institution:</p>
              <div className="flex items-center">
                <p className="font-bold">{graduationData.institution}</p>
              </div>
            </div>
          </div>
          <div className=" border-b border-tertiary">
            <div className="grid grid-cols-2 py-2">
              <p className="font-bold text-primary">Faculty:</p>
              <div className="flex items-center">
                <p className="font-bold">{graduationData.faculty}</p>
              </div>
            </div>
          </div>
          <div className=" border-b border-tertiary">
            <div className="grid grid-cols-2 py-2">
              <p className="font-bold text-primary">Degree:</p>
              <div className="flex items-center">
                <p className="font-bold">{graduationData.degree}</p>
              </div>
            </div>
          </div>
          <div className=" border-b border-tertiary">
            <div className="grid grid-cols-2 py-2">
              <p className="font-bold text-primary">Graduation Date:</p>
              <div className="flex items-center">
                <p className="font-bold">{graduationData.graduationDate ?? graduationData.date}</p>
              </div>
            </div>
          </div>
        </div>
        {/* QR Code */}
        <div className="grid grid-cols-2 py-3">
          <div>
            {/* Generated QR code */}
            <div className="bg-gallery w-48 h-52 my-5 flex items-center justify-center">
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={'https://master.d3bpewju4xt70g.amplifyapp.com' ?? ''}
                viewBox={`0 0 256 256`}
              />
            </div>

            <p
              onClick={() => window.open('https://master.d3bpewju4xt70g.amplifyapp.com', '_blank')}
              className="text-gray text-md pt-2"
              style={{
                cursor: 'pointer',
              }}
            >
              Go to student portal
            </p>
          </div>
          {/* <div className="flex flex-col gap-4 items-center justify-center">
            <button className="bg-white text-primary font-bold py-2 px-4 border border-primary rounded-lg inline-flex items-center">
              <span>Copy</span>
            </button>
            <button className="bg-white text-primary font-bold py-2 px-4 border border-primary rounded-lg inline-flex items-center">
              <span>Download</span>
            </button>
            <button className="bg-white text-primary font-bold py-2 px-4 border border-primary rounded-lg inline-flex items-center">
              <span>Share</span>
            </button>
          </div> */}
        </div>
      </div>
      {/* Stream Keys */}
      <div className="py-4">
        {/* Table Titles */}
        <div className="grid grid-cols-5 py-2 px-2 bg-primary rounded-t-lg font-bold text-white">
          <p>Stream Name</p>
          <p>Streaming Server</p>
          <p>Stream Key</p>
          <p>Playback URL</p>
          <p>Action</p>
        </div>
        {/* Table Items */}
        <div className="grid grid-cols-5 mt-3 py-2 px-2 bg-gallery rounded-lg">
          <div className="flex items-center gap-2">
            <p className="py-1 px-2">00763800</p>
          </div>
          <div className="flex items-center gap-2">
            <p className="bg-white py-1 px-2 border border-ash">Streaming Server</p>
            <button>
              <img src={copyIcon} alt="copy icon" />
            </button>
          </div>
          <div className="flex items-center gap-2">
            <p className="bg-white py-1 px-2 border border-ash">Streaming Key</p>
            <button>
              <img src={copyIcon} alt="copy icon" />
            </button>
          </div>
          <div className="flex items-center gap-2">
            <p className="bg-white py-1 px-2 border border-ash">Playback Url</p>
            <button>
              <img src={copyIcon} alt="copy icon" />
            </button>
          </div>
          <div className="flex gap-3">
            <button className="bg-white text-primary font-medium py-1 px-3 border border-primary rounded-lg inline-flex items-center">
              <span>Share</span>
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-center py-10">
        <button
          className="bg-primary text-white font-bold py-1.5 px-12 rounded-lg inline-flex items-center gap-3"
          onClick={() => onClickFinish()}
        >
          <span>{loading ? <CircularProgressWithLabel /> : 'Finish'}</span>
        </button>
      </div>
    </div>
  );
};

export default QrCode;
