import React, { FC, useRef, useState, ChangeEvent, FormEvent, useEffect } from 'react';
import cloudUploadIcon from '../../assets/images/upload1.svg';
import uploadIcon from '../../assets/images/upload.svg';
import editIcon from '../../assets/images/create.svg';
import APIService from '../../network/APIService';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';

interface FormData {
  firstName: string;
  lastName: string;
  position: string;
  phoneNumber: string;
  email: string;
  institutionName: string;
  department: string;
}

const AccountProfile: FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [logo, setLogo] = useState<string>('');

  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    position: '',
    phoneNumber: '',
    email: '',
    institutionName: '',
    department: '',
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleUploadClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    setUploadedImage(selectedFile);
    try {
      const response = await APIService.uploadInstituteLogo(selectedFile);
      if (response.status === 200) {
        setLoading(false);
        setLogo(response.data.logo);
        localStorage.setItem('userImage', response.data.logo);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value, id } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleFormSave = (e: FormEvent): void => {
    e.preventDefault();
    console.log(formData);
    updateUserInfo();
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    setLoading(true);
    try {
      const response = await APIService.getProfile();
      if (response.status === 200) {
        setLoading(false);
        setFormData({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          position: response.data.userPosition,
          phoneNumber: response.data.phone,
          email: response.data.email,
          institutionName: response.data.institutionName,
          department: response.data.department,
        });
        setLogo(response.data.logo);
        localStorage.setItem('userImage', response.data.logo);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const updateUserInfo = async () => {
    setLoading(true);
    try {
      const obj = {
        details: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          userPosition: formData.position,
          phone: formData.phoneNumber,
          institutionName: formData.institutionName,
        },
      };
      const response = await APIService.updateProfile(obj);
      if (response.status === 200) {
        getUserInfo();
        setLoading(false);
        setFormData({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          position: response.data.userPosition,
          phoneNumber: response.data.phone,
          email: response.data.email,
          institutionName: response.data.institutionName,
          department: response.data.department,
        });
        setLogo(response.data.logo);
      } else {
        getUserInfo();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return loading ? (
    <div className="w-3/4 flex  justify-center items-center p-4 gap-2">
      <CircularProgressWithLabel />
      Loading...
    </div>
  ) : (
    <div>
      <h1 className="text-3xl font-bold m-0 leading-relaxed tracking-wide">Account Profile</h1>
      <h2 className="leading-8">Kindly fill in your personal and institution details.</h2>
      <div className="py-8">
        <h1 className="text-xl font-bold m-0 leading-normal tracking-normal border-b-2 border-tertiary">
          Institution Logo
        </h1>
        <div className="flex gap-3 lg:gap-24">
          <div
            className=" w-48 h-40 my-5 flex items-center justify-center cursor-pointer" //bg-gallery
            onClick={handleUploadClick}
          >
            {uploadedImage && (
              <div>
                <img
                  src={URL.createObjectURL(uploadedImage)}
                  alt="Uploaded logo"
                  className="h-36"
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleFileChange}
                />
              </div>
            )}
            {!uploadedImage && (
              <div className="bg-catalyst rounded-full flex justify-center items-center w-100 h-100">
                <img src={logo} />
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleFileChange}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col items-center justify-center gap-4">
            <h2 className="leading-8 text-center">PNG, JPG Or JPEG are allowed</h2>
            <button
              className="bg-primary text-white font-bold py-2 px-4 rounded inline-flex items-center gap-3"
              onClick={handleUploadClick}
            >
              <span>Upload</span>
              <img src={uploadIcon} />
            </button>
          </div>
        </div>
      </div>
      <div className="pb-8">
        <h1 className="text-xl font-bold m-0 leading-normal tracking-normal border-b-2 border-tertiary">
          Personal Details
        </h1>
        <form className="w-full ">
          <div className="grid grid-cols-2 py-3">
            <p className="font-bold">First Name</p>
            <div className="flex items-center border-b border-ash py-2">
              <input
                className="appearance-none bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight"
                type="text"
                placeholder="Enter First Name"
                id={'firstName'}
                value={formData.firstName}
                onChange={handleInputChange}
              />
              <img src={editIcon} alt="pencil" style={{ fill: '#a7a7a7' }} />
            </div>
          </div>
          <div className="grid grid-cols-2 py-3">
            <p className="font-bold">Last Name</p>
            <div className="flex items-center border-b border-ash py-2">
              <input
                className="appearance-none bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight"
                type="text"
                placeholder="Enter Last Name"
                id={'lastName'}
                value={formData.lastName}
                onChange={handleInputChange}
              />
              <img src={editIcon} alt="pencil" style={{ fill: '#a7a7a7' }} />
            </div>
          </div>
          <div className="grid grid-cols-2 py-3">
            <p className="font-bold">Department</p>
            <div className="flex items-center border-b border-ash py-2">
              <input
                className="appearance-none bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight"
                type="text"
                placeholder="Enter Last Name"
                id={'lastName'}
                value={formData.department}
                onChange={handleInputChange}
              />
              <img src={editIcon} alt="pencil" style={{ fill: '#a7a7a7' }} />
            </div>
          </div>
          <div className="grid grid-cols-2 py-3">
            <p className="font-bold">Position</p>
            <div className="flex items-center border-b border-ash py-2">
              <input
                className="appearance-none bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight"
                type="text"
                placeholder="Enter Position"
                id={'position'}
                value={formData.position}
                onChange={handleInputChange}
              />
              <img src={editIcon} alt="pencil" style={{ fill: '#a7a7a7' }} />
            </div>
          </div>
          <div className="grid grid-cols-2 py-3">
            <p className="font-bold">Phone Number</p>
            <div className="flex items-center border-b border-ash py-2">
              <input
                className="appearance-none bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight"
                type="tel"
                id="phoneNumber"
                placeholder="Enter Phone Number"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
              <img src={editIcon} alt="pencil" style={{ fill: '#a7a7a7' }} />
            </div>
          </div>
          <div className="grid grid-cols-2 py-3">
            <p className="font-bold">Email</p>
            <div className="flex items-center border-b border-ash py-2">
              <input
                className="appearance-none bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight"
                type="email"
                placeholder="Enter Email Address"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <img src={editIcon} alt="pencil" style={{ fill: '#a7a7a7' }} />
            </div>
          </div>
          <div className="py-8">
            <h1 className="text-xl font-bold m-0 leading-normal tracking-normal border-b-2 border-tertiary">
              Institution Details
            </h1>
            <div className="grid grid-cols-2 py-3">
              <p className="font-bold">Institution Name</p>
              <div className="flex items-center border-b border-ash py-2">
                <input
                  className="appearance-none bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight"
                  type="text"
                  placeholder="Enter Institution Name"
                  id="institutionName"
                  value={formData.institutionName}
                  onChange={handleInputChange}
                />
                <img src={editIcon} alt="pencil" style={{ fill: '#a7a7a7' }} />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="flex justify-center align-center">
        <button
          type="submit"
          className="bg-primary text-white font-bold py-2 px-4 rounded-lg inline-flex items-center gap-3"
          onClick={handleFormSave}
        >
          <span>{loading ? <CircularProgressWithLabel /> : 'Save'}</span>
        </button>
      </div>
    </div>
  );
};

export default AccountProfile;
