import {FC, useEffect, useState} from 'react';
import editIcon from '../../../assets/images/create.svg';
// import calenderIcon from "../../../assets/images/calendar.svg";
import ellipsisIcon from '../../../assets/images/ellipsis.svg';
import GraduationData from '../GraduationData'

interface GraduationDetailsProps {
    activeTab: string;
    graduationData: GraduationData;
    onTabClick: (tabId: string) => void;
    loading: boolean;
}


const GraduationDetails: FC<GraduationDetailsProps> = ({
                                                           activeTab,
                                                           onTabClick,
                                                           graduationData,
                                                           loading,
                                                       }) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [formData, setFormData] = useState<GraduationData>({});

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

    }
    useEffect(() => {
        if (graduationData) {
            setFormData(graduationData);
        }
    })
    console.log('graduationData', graduationData);
    return (
        <div>
            <div className="pb-10">
                <div className="flex justify-between py-3">
                    <h1 className="text-xl font-bold m-0 leading-normal tracking-normal border-b-2 border-tertiary">
                        Graduation Settings
                    </h1>
                    <button
                        className={`bg-primary text-white font-bold py-2 px-4 rounded-lg inline-flex items-center gap-3 ${
                            isButtonDisabled ? 'bg-ash cursor-not-allowed' : ''
                        }`}
                        disabled={isButtonDisabled}
                    >
                        <span>Reset Graduation Details</span>
                    </button>
                </div>
                <form className="w-full ">
                    <div className="grid grid-cols-2 py-3">
                        <p className="font-bold">Graduation Code</p>
                        <div className="flex items-center border-b border-ash py-2">
                            <p>{formData.group_name}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 py-3">
                        <p className="font-bold">Year</p>
                        <div className="flex items-center border-b border-ash py-2">
                            <input
                                className="appearance-none bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight"
                                name="year"
                                // value={formData.year}
                                placeholder="Enter Graduation Year"
                                value={formData.year}
                            />
                            <img src={editIcon} alt="pencil" style={{fill: '#a7a7a7'}}/>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 py-3">
                        <p className="font-bold">Semester</p>
                        <div className="flex items-center border-b border-ash py-2">
                            <input
                                readOnly
                                className="appearance-none bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight"
                                type="text"
                                placeholder="Enter Graduation Semester"
                                name="semester"
                                value={formData.semester}
                                onChange={handleInputChange}
                            />
                            <img src={ellipsisIcon} alt="pencil" style={{fill: '#a7a7a7'}}/>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 py-3">
                        <p className="font-bold">Faculty</p>
                        <div className="flex items-center border-b border-ash py-2">
                            <input
                                readOnly
                                className="appearance-none bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight"
                                type="text"
                                placeholder="Enter Graduation Faculty"
                                name="faculty"
                                value={formData.faculty}
                                onChange={handleInputChange}
                            />
                            <img src={ellipsisIcon} alt="pencil" style={{fill: '#a7a7a7'}}/>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 py-3">
                        <p className="font-bold">Degree</p>
                        <div className="flex items-center border-b border-ash py-2">
                            <input
                                readOnly
                                className="appearance-none bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight"
                                type="tel"
                                placeholder="Enter Degree Name"
                                name="degree"
                                value={formData.degree}
                                onChange={handleInputChange}
                            />
                            <img src={ellipsisIcon} alt="pencil" style={{fill: '#a7a7a7'}}/>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 py-3">
                        <p className="font-bold">Graduation Date</p>
                        <div className="flex items-center border-b border-ash py-2">
                            <input
                                readOnly
                                className="appearance-none bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight"
                                type="date"
                                placeholder="Enter Graduation Date"
                                name="graduationDate"
                                defaultValue={formData.graduationDate}
                                value={formData.graduationDate}
                            />
                            {/* <img src={calenderIcon} alt="pencil" style={{ fill: '#a7a7a7' }} /> */}
                        </div>
                    </div>
                </form>
            </div>
            {/* <button
        className="bg-primary text-white font-bold py-2 px-4 rounded-lg inline-flex items-center gap-3"
        onClick={handleFormSave}
      >
        <span>Save</span>
      </button> */}
            <div className="flex justify-center align-center py-8">
                {!loading ? (
                    <button
                        type="submit"
                        //   className="bg-primary text-white font-bold py-2 px-4 rounded-lg inline-flex items-center gap-3"
                        className={`bg-primary text-white font-bold py-2 px-4 rounded-lg inline-flex items-center gap-3`}
                        disabled={
                            !formData.degree &&
                            !formData.faculty &&
                            !formData.graduationDate &&
                            !formData.semester &&
                            !formData.year
                        }
                    >
                        <span>Continue</span>
                    </button>
                ) : (
                    <button
                        type="submit"
                        //   className="bg-primary text-white font-bold py-2 px-4 rounded-lg inline-flex items-center gap-3"
                        className={`bg-primary text-white font-bold py-2 px-4 rounded-lg inline-flex items-center gap-3`}
                        disabled={true}
                    >
                        <span>Processing</span>
                    </button>
                )}
            </div>
        </div>
    );
};

export default GraduationDetails;
