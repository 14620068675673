import React, { useRef } from 'react';
import phoneImage from '../../assets/images/heroPhone.svg';
import logo from '../../assets/images/logo3.svg';
import CustomButton from '../../components/CustomButton';
import ContactForm from '../../components/ContactForm';

const Home: React.FC = () => {
  const contactFormRef = useRef<HTMLDivElement>(null);

  const handleLoginClick = (): void => {
    window.location.href = '/login';
  };

  const handleScrollToContactForm = (): void => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-primary">
      {/* Hero Section */}
      <section className="h-screen bg-primary text-white flex-shrink-0">
        {/* Header */}
        <header className="flex items-center justify-between px-4 py-4 mx-auto max-w-screen-lg">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="h-8 lg:h-12 mr-2" />
          </div>
          <div>
            <button
              className="uppercase text-white text-center font-bold px-5 border-2 h-10 border-white rounded-lg hover:border-yellow"
              onClick={handleLoginClick}
            >
              Login
            </button>
          </div>
        </header>
        {/* Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 p-4 h-[500px]">
          <div className="bg-gray-200 p-2 rounded-md flex items-center justify-center max-h-full">
            <img
              src={phoneImage}
              alt="Phone"
              className="max-w-full w-64 lg:w-full xl:w-full object-cover"
              style={{ maxHeight: '100%', maxWidth: '60%' }}
            />
          </div>
          <div className="bg-gray-200 px-4 rounded-md flex flex-col justify-center ">
            <h2 className="font-regular text-xl lg:text-5xl lg:text-5xl mb-2 lg:mb-4">
              Capturing Milestones,
            </h2>
            <h2 className="font-regular text-xl lg:text-5xl lg:text-5xl mb-3 lg:mb-8">
              Creating Memories
            </h2>
            <h3 className="font-poppins text-sm lg:text-lg lg:text-lg mb-3 lg:mb-6">
              Elevating graduation ceremonies into lasting memories with personalised video clips
              for every graduate
            </h3>
            <CustomButton onClick={handleScrollToContactForm} />
          </div>
        </div>
      </section>
      {/* Contact Form */}
      <div className="flex-shrink-0 md:h-screen bg-secondary flex items-center justify-center">
        <section ref={contactFormRef} className="mb-32 bg-primary md:rounded-xl">
          <div className="flex flex-wrap items-center justify-center">
            <div className="mb-10 w-full shrink-0 grow-0 basis-auto px-2 md:mb-0 md:w-6/12 md:px-3 lg:px-6">
              <h2 className="mb-6 text-3xl font-bold text-white text-center uppercase">
                Get in touch with us
              </h2>
              <p className="mb-6 p-2 text-black">
                Discover how <span className="text-secondary">Gradcut</span> effortlessly edits and
                shares branded and personalized graduation clips with students right after the event
                stream.
              </p>
            </div>
            <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:px-6 bg-white rounded-tl rounded-bl rounded-xl p-6">
              <ContactForm />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
