import {FC, useEffect, useState} from 'react';
import GraduationDetails from './GraduationDetails';
import UploadStudentList from './UploadStudentList';
import QrCode from './QrCode';
import APIService from '../../network/APIService';
import {useNavigate, useParams} from 'react-router-dom';
import GraduationData from './GraduationData'

const NewGraduation: FC = () => {
    const [activeTab, setActiveTab] = useState<string>(window.location.hash ? 'page3' : 'page1');
    const [loading, setLoading] = useState<boolean>(false);
    const [graduationData, setGraduationData] = useState<GraduationData>({});
    const [graduationList, setGraduationList] = useState<any>([]);
    const navigate = useNavigate();

    const {id} = useParams();
    const handleTabClick = (tabId: string): void => {
        setActiveTab(tabId);
    };

    useEffect(() => {
        console.log('id', id);
        if (id) {
            loadGraduation(id).catch(console.error);
        }

    }, [activeTab]);

    const loadGraduation = async (id: string) => {
        setLoading(true);
        try {
            const response = await APIService.fetchSingleGraduation(id);
            console.log('response', response);
            setLoading(false);
            setGraduationData(response);
        } catch (error) {
            console.error('error', error);
        }
    };

    const renderContent = (): React.ReactNode => {
        switch (activeTab) {
            case 'page1':
                return (
                    <GraduationDetails
                        activeTab={activeTab}
                        graduationData={graduationData}
                        onTabClick={handleTabClick}
                        loading={loading}
                    />
                );
            case 'page2':
                return <UploadStudentList activeTab={activeTab} onTabClick={handleTabClick} graduationData={graduationData}/>;
            case 'page3':
                return (
                    <QrCode
                        activeTab={activeTab}
                        graduationData={graduationData}
                        graduationList={graduationList}
                        loading={loading}
                        onTabClick={handleTabClick}
                        onClickFinish={() => {
                            setActiveTab('page1');
                            navigate('/graduation-library');
                            setGraduationData({});
                            setGraduationList([]);
                        }}
                    />
                );
            default:
                return null;
        }
    };

    const validateFields = (_: any) => {
        if (
            _.year === null ||
            _.semester === '' ||
            _.faculty === '' ||
            _.degree === '' ||
            _.graduationDate === ''
        ) {
            console.log('graduationData', _);
            alert('Please fill all the fields');
        } else {
            handleCreateGraduation(_);
        }
    };

    const handleCreateGraduation = async (data: any) => {
        setLoading(true);
        try {
            const obj = {
                year: data.year,
                institution: data.semester,
                faculty: data.faculty,
                degree: data.degree,
                date: data.graduationDate,
            };
            const response = await APIService.createGraduation(obj);
            console.log('response', response);
            if (response.status === 201) {
                setGraduationData(response.data);
                setTimeout(() => {
                    setActiveTab('page2');
                }, 500);
                setLoading(false);
            } else {
                // setGraduationList(response.data);
                setLoading(false);
            }
        } catch (error) {
            console.error('error', error);
        }
    };

    return (
        <div>
            <h1 className="text-3xl font-bold m-0 leading-relaxed tracking-wide">New Graduation</h1>
            <div>
                <ul className="grid grid-flow-col text-center border-b border-tertiary text-black font-medium">
                    <li>
                        <a
                            href="#page1"
                            className={`flex justify-center border-b-4 ${activeTab === 'page1' ? 'border-black text-black font-extrabold' : 'border-transparent'} hover:text-primary hover:border-primary py-4`}
                            onClick={() => handleTabClick('page1')}
                        >
                            Graduation Details
                        </a>
                    </li>
                    <li>
                        <a
                            href="#page2"
                            className={`flex justify-center border-b-4 ${activeTab === 'page2' ? 'border-black text-black font-extrabold' : 'border-transparent'} hover:text-primary hover:border-primary py-4`}
                            onClick={() => handleTabClick('page2')}
                        >
                            Upload Student List
                        </a>
                    </li>
                    <li>
                        <a
                            href="#page3"
                            className={`flex justify-center border-b-4 ${activeTab === 'page3' ? 'border-black text-black font-extrabold' : 'border-transparent'} hover:text-primary hover:border-primary py-4`}
                            onClick={() => handleTabClick('page3')}
                        >
                            QR Code
                        </a>
                    </li>
                </ul>
            </div>
            <div className="mt-4">{renderContent()}</div>
        </div>
    );
};

export default NewGraduation;
