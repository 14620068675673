import axios from 'axios';
import {User, UserDAO} from './user';
import * as AWS from 'aws-sdk';
import {DocumentClient} from "aws-sdk/clients/dynamodb";
import {plainToInstance} from "class-transformer";
import GraduationData from "../pages/NewGraduation/GraduationData";
import {camelizeKeys, decamelizeKeys} from "fast-case";
import PreSignedUrl from "../pages/NewGraduation/PreSignedUrl";
import {env} from "../env";
const API_BASE_URL = env.API_BASE_URL;

class APIService {
    api: any;
    token: string | null;

    constructor() {
        this.api = axios.create({
            baseURL: API_BASE_URL,
            // You can add additional Axios configuration options here, e.g. headers, timeout, etc.
        });
        this.token = localStorage.getItem('token');
    }

    async signup(data: any): Promise<any> {
        try {
            const response = await this.api.post('auth/sign-up', data);
            return response;
        } catch (error) {
            return error;
        }
    }

    async login(data: any) {
        try {
            // const response = await this.api.post('verify-user', data);
            const user = await UserDAO.getUserByEmail(data.email);
            if (user) {
                const id = User.getPK(user.userMap.email);
                return {body: id};
            }
            return {
                message: 'User not found',
            };
        } catch (error) {
            console.log('error ', error);
            return error;
        }
    }

    async getProfile() {
        try {
            const response = await this.api.get('user/details', {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            });
            return response;
        } catch (error) {
            console.log('error ', error);
            return error;
        }
    }

    async updateProfile(data: any) {
        try {
            const response = await this.api.post('user/details', data, {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            });
            return response;
        } catch (error) {
            console.log('error ', error);
            return error;
        }
    }

    async getAssets() {
        try {
            const response = await this.api.get('assets', {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            });
            return response;
        } catch (error) {
            console.log('error ', error);
            return error;
        }
    }

    async getGraduation() {
        try {
            const response = await this.api.get('graduations', {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            });
            return response;
        } catch (error) {
            console.log('error ', error);
            return error;
        }
    }

    async uploadAssets(type: string, data: any) {
        const formData = new FormData();
        formData.append('files[]', data);
        try {
            const response = await this.api.post(`assets/${type}`, formData, {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            });
            return response;
        } catch (error) {
            console.log('error ', error);
            return error;
        }
    }

    async uploadBranding(type: string, data: any) {
        try {
            const response = await this.api.post(`assets/${type}`, data, {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            });
            return response;
        } catch (error) {
            console.log('error ', error);
            return error;
        }
    }

    async uploadStudentList(uploadUrl: PreSignedUrl, data: any) {
        const formData = new FormData();
        for (const key in uploadUrl.fields) {
            formData.append(key, uploadUrl.fields[key]);
        }
        formData.append('file', data);
        try {
            const response = await this.api.post(uploadUrl.url, formData);
            return response;
        } catch (error) {
            console.log('error ', error);
            return error;
        }
    }

    async getStudentList() {
        try {
            const response = await this.api.get(`reteive-student-list`, {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            });
            return response;
        } catch (error) {
            console.log('error ', error);
            return error;
        }
    }

    async logout() {
        try {
            const response = await this.api.delete('auth/sign-out', {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            });
            return response.data;
        } catch (error) {
            console.log('error ', error);
            throw error;
        }
    }

    async uploadInstituteLogo(data: any) {
        const formData = new FormData();
        formData.append('files[]', data);
        try {
            const response = await this.api.post('user/upload-logo', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            return response;
        } catch (error) {
            console.log('error ', error);
            return error;
        }
    }

    async createGraduation(data: any) {
        try {
            const response = await this.api.post('graduations', data, {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            });
            return response;
        } catch (error) {
            console.log('error ', error);
            return error;
        }
    }

    async getUploadedStudentList() {
        try {
            const response = await this.api.get('format-sample', {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            });
            return response;
        } catch (error) {
            console.log('error ', error);
            return error;
        }
    }

    async deleteGraduation(data: any) {
        try {
            const response = await this.api.delete(`graduations/${data}`, {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            });
            return response;
        } catch (error) {
            console.log('error ', error);
            return error;
        }
    }

    async fetchSingleGraduation(id: string): Promise<GraduationData> {
        const response = await this.api.get(`graduations/${id}`, {
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        })
            .then((response: any) => response.data)
            .then((data: any) => plainToInstance(GraduationData, camelizeKeys(data)));
        return response;
    }

    async getStudentListUploadUrl(id: number, fileName: string): Promise<PreSignedUrl> {
        const response = await this.api.get(`graduations/${id}/upload-url/${fileName}`, {
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        })
            .then((response: any) => response.data)
            .then((data: any) => plainToInstance(PreSignedUrl, camelizeKeys(data)));
        return response;
    }

    async getStudentListDownloadUrl(id: number): Promise<PreSignedUrl> {
        const response = await this.api.get(`graduations/${id}/download-url`, {
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        })
            .then((response: any) => response.data)
            .then((data: any) => plainToInstance(PreSignedUrl, camelizeKeys(data)));
        return response;
    }
}

export default new APIService();
