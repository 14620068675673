import { FC, useState, useRef, useEffect, useMemo } from 'react';
import APIService from '../../network/APIService';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import {Link, useNavigate} from 'react-router-dom';

interface GraduationData {
    id: number;
    group_name: string;
    year: string;
    semester: string;
    faculty: string;
    degree: string;
    graduation_date: string;
    graduation_time: string;
}

const GraduationLibrary: FC = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState<number | null>(1);
  const container1Ref = useRef<HTMLDivElement>(null);
  const [graduationData, setGraduationData] = useState<GraduationData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [renderArr, setRenderArr] = useState<any>([]);

  const toggleAccordion = (): void => {
    // setSelected((prev) => (prev !== 1 ? 1 : null));
    const arr: any = [];
    renderArr.forEach((grad: any) => {
      arr.push({
        ...grad,
        opened: !grad.opened,
      });
    });
    setRenderArr(arr);
  };

  useEffect(() => {
    getGraduation();
  }, []);

  const getGraduation = async () => {
    setLoading(true);
    try {
      const response = await APIService.getGraduation();
      console.log('response', response);
      if (response.status === 200) {
        setLoading(false);
        setGraduationData(response.data);
        const arr: any = [];
        setRenderArr(arr);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleDelete = async (date: any) => {
    setLoading(true);
    try {
      const response = await APIService.deleteGraduation(date);
      console.log('response', response);
      if (response.status === 200) {
        setLoading(false);
        await getGraduation();
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  // const container1Style: React.CSSProperties =
  //   selected === 1 ? { maxHeight: `${container1Ref.current?.scrollHeight}px` } : {};
  return loading ? (
    <div className="w-3/4 flex  justify-center items-center p-4 gap-2">
      <CircularProgressWithLabel />
      Loading...
    </div>
  ) : (
    <>
      <h1 className="text-3xl font-bold m-0 leading-relaxed tracking-wide">Graduation Library</h1>
      <div className="py-4">
          <div className="grid grid-cols-10 py-2 px-2 bg-primary rounded-t-lg font-bold text-white">
              <p>Year</p>
              <p className="col-span-2">Group</p>
              <p className="col-span-2">Graduation Date</p>
              <p className="col-span-3">Faculty</p>
              <p className="col-span-2">Degree</p>
          </div>
      </div>
        {graduationData.map((graduation) => (
            <div className="py-2" >
                    {/* Title */}
                <div className="grid grid-cols-10 rounded-t-lg">
                    <p>{graduation.year}</p>
                    <p className="col-span-2">
                        <Link
                            to={`/graduation/${graduation.id}`}
                        > {graduation.group_name}</Link></p>
                    <p className="col-span-2">{graduation.graduation_date}</p>
                    <p className="col-span-3">{graduation.faculty}</p>
                    <p className="col-span-2">{graduation.degree}</p>
                </div>
            </div>
        ))}

    </>
  );
};

export default GraduationLibrary;
