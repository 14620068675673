import React, { useState } from 'react';
import illustration from '../../assets/images/loginIllustration.svg';
import logo from '../../assets/images/logo2.svg';
import SignInForm from '../../components/SignInForm';
import { useNavigate } from 'react-router-dom';
import * as urls from '../../routePaths';

const LogIn: React.FC = () => {
  const navigate = useNavigate();

  const toggleForm = (): void => {
    navigate(urls.SINGUP);
  };

  return (
    <div className="relative w-full h-screen flex flex-col lg:flex-row">
      {/* Central square overlay */}
      <div className="hidden lg:flex absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/4 h-3/4 bg-white rounded-lg z-10">
        {/* Left */}
        <div className="w-1/2 bg-secondary h-full rounded-l-lg flex flex-col">
          {/* Logo */}
          <div className="flex justify-center mt-4">
            <img className="max-w-52" src={logo} alt="logo" />
          </div>
          {/* Illustration */}
          <div className="mt-auto">
            <img className="w-full h-full object-cover" src={illustration} alt="illustration" />
          </div>
        </div>
        {/* Right */}
        <div className="w-1/2 bg-white h-full rounded-r-lg flex flex-col justify-center items-center pl-4">
          <SignInForm />
          <div className="mt-4 max-w-sm mx-auto w-full">
            <div className="w-3/4 flex justify-center">
              <p className="text-gray ">
                New User?
                <span
                  className="ml-2 text-primary font-bold border-2 border-x-0 border-t-0 border-b-primary cursor-pointer"
                  onClick={toggleForm}
                >
                  SignUp
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Small Devices */}
      <div className="relative bg-primary lg:w-1/2 h-1/6 md:h-screen lg:h-auto">
        <div className="lg:hidden flex items-center justify-center mt-6">
          {/* Logo */}
          <img className="max-w-52" src={logo} alt="logo" />
        </div>
      </div>
      <div className="relative bg-secondary lg:w-1/2 h-5/6 md:h-screen lg:h-auto">
        <div className="lg:hidden h-full relative">
          <div className="absolute bottom-0 w-full">
            <img className="w-full h-full object-cover" src={illustration} alt="illustration" />
          </div>
          {/* Square */}
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/4 h-3/4 bg-white bg-opacity-75 rounded-lg z-10">
            <div className="p-2 m-2">
              <SignInForm />
              <div className="mt-0 lg:mt-4 max-w-sm mx-auto w-full">
                <div className="w-full flex justify-center">
                  <p className="text-gray ">
                    New User?
                    <span
                      className="ml-2 text-primary font-bold border-2 border-x-0 border-t-0 border-b-primary cursor-pointer"
                      onClick={toggleForm}
                    >
                      SignUp
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
