import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as urls from '../../routePaths';
import APIService from '../../network/APIService';
import CircularProgressWithLabel from '../CircularProgressWithLabel';
import {useAuth} from "../../hooks/useAuth";

const SignInForm: React.FC = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [loginInfo, setLoginInfo] = useState<any>({
        email: '',
        password: '',
    });
    const [loading, setLoading] = useState<boolean>(false);

    const handleCheckboxChange = (): void => {
        setIsChecked((prev: boolean) => !prev);
    };

    const handleRedirect = (): void => {
        navigate(urls.ACCOUNT_PROFILE);
    };

    const validateForm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        if (loginInfo.email === '' || loginInfo.password === '') {
            alert('Please fill all the fields');
        } else if (isChecked === false) {
            alert('Please accept the terms and conditions');
        } else {
            handleApi();
        }
    };

    const handleApi = async () => {
        setLoading(true);
        const result = await auth.signIn(loginInfo.email, loginInfo.password);
        if (result.success) {
            localStorage.setItem("token", "N/A");
            handleRedirect();
        } else {
            alert(result.message);
        }
        setLoading(false);
    };



    return (
        <div>
            <form className="max-w-sm mx-auto">
                <h1 className="font-bold text-primary text-4xl ml-4">SIGN IN</h1>
                <p className="text-gray mt-2 mb-6">Welcome Back</p>
                <input
                    type="email"
                    id="email"
                    placeholder="EMAIL ADDRESS *"
                    className="w-3/4 mb-5 text-sm text-primary placeholder-primary"
                    value={loginInfo.email}
                    onChange={(e) => setLoginInfo({...loginInfo, email: e.target.value})}
                />
                <input
                    type="password"
                    id="password"
                    placeholder="PASSWORD *"
                    className="w-3/4 mb-5 text-sm text-primary placeholder-primary"
                    value={loginInfo.password}
                    onChange={(e) => setLoginInfo({...loginInfo, password: e.target.value})}
                />
                <div className="flex items-center mb-4">
                    <input
                        id="default-checkbox"
                        type="checkbox"
                        value=""
                        className="text-primary focus:ring-2 focus:ring-gray"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="default-checkbox" className="ml-2 text-xs font-bold text-primary">
                        ACCEPT THE TERMS AND CONDITIONS
                    </label>
                </div>
                <div className="w-3/4 flex flex-col justify-center items-center p-4">
                    <button
                        className={`bg-${isChecked ? 'primary' : 'ash'} text-base text-white font-medium px-10 py-2 rounded-md ${isChecked ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                        disabled={!isChecked}
                        onClick={validateForm}
                    >
                        {loading ? <CircularProgressWithLabel/> : 'Sign In'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SignInForm;
