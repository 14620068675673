class GraduationData {
    id?: number;
    group_name?: string
    year?: number;
    semester?: string;
    faculty?: string;
    degree?: string;
    graduationDate?: string;
    graduationTime?: string;
    studentListFilename?: string;
    studentListLastUpdated?: Date;
    created_at?: string;

}

export default GraduationData;