import { DocumentClient } from 'aws-sdk/clients/dynamodb';

export class UserMap {
  /* @ts-ignore */
  firstName: string;
  /* @ts-ignore */
  lastName: string;
  /* @ts-ignore */
  institutionName: string;
  /* @ts-ignore */
  department: string;
  /* @ts-ignore */
  email: string;
  /* @ts-ignore */
  password: string;
  position?: string;
  phone?: string;
  logo?: string;
}

function normalizeString(
  source: string,
  symbolsToReplace: string,
  replaceWith: string,
  capitalize = false,
): string {
  let result = source;
  symbolsToReplace.split('').forEach((symbol) => {
    let splitStrings = result.split(symbol);
    if (capitalize) {
      splitStrings = splitStrings.map((el) => el.charAt(0).toUpperCase() + el.substring(1));
    }
    result = splitStrings.join(replaceWith);
  });
  return result;
}

export class User {
  static readonly TENANT_PREFIX = 'TENANT#';
  static readonly PROJECT_PREFIX = 'USER';

  tenantId: string;
  userMap: UserMap;

  constructor(item: DocumentClient.AttributeMap) {
    this.tenantId = User.getTenantId(item.PK);
    this.userMap = item.users;
  }

  static getPK(email: string) {
    const tenantId = normalizeString(email, '@.', '-');
    return `${User.TENANT_PREFIX}${tenantId}`;
  }

  static getSK() {
    return `${User.PROJECT_PREFIX}`;
  }

  private static getTenantId(PK: string) {
    if (PK.startsWith(this.TENANT_PREFIX)) {
      return PK.substring(this.TENANT_PREFIX.length);
    }
    throw new Error(`Invalid PK: ${PK}`);
  }
}

export class UserDAO {
  static readonly TABLE_NAME: string = 'user';
  private static DYNAMODB: DocumentClient;
  static async getUserByEmail(email: string): Promise<User | undefined> {
    const dynamodb = this.getDynamodb();
    const result = await dynamodb
      .query({
        TableName: this.TABLE_NAME,
        KeyConditionExpression: 'PK = :pk', // Use 'PK' as the key condition expression
        ExpressionAttributeValues: {
          ':pk': User.getPK(email), // Set the value for 'PK'
        },
      })
      .promise();

    //@ts-ignore
    return result.Items.length > 0 ? new User(result.Items[0]) : undefined;
  }
  static async getList(): Promise<any | undefined> {
    const dynamodb = this.getDynamodb();
    const token = localStorage.getItem('token');
    console.log({ token });
    const params: DocumentClient.GetItemInput = {
      TableName: 'lists',
      Key: {
        PK: token,
        SK: 'LIST',
      },
    };
    const result = await dynamodb.get(params).promise();
    //@ts-ignore
    return result.Item;
  }
  private static getDynamodb(): DocumentClient {
    if (this.DYNAMODB == undefined) {
      this.DYNAMODB = new DocumentClient();
    }
    return this.DYNAMODB;
  }
}
