import { FC, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ACCOUNT_PROFILE,
  BRAND_KIT_URL,
  NEW_GRADUATION,
  GRADUATION_LIBRARY,
} from '../../routePaths';
import styles from './Burger.module.scss';
import classNames from 'classnames';

const Burger: FC = () => {
  const [showBurger, setShowBurger] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const showBurgerHandler = (): void => {
    setShowBurger((current) => !current);
  };

  const handleClickOutside = (event: Event): void => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setShowBurger(false);
    }
  };

  useEffect(() => {
    if (showBurger) {
      document.addEventListener('click', handleClickOutside, true);

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, [showBurger]);

  return (
    <div ref={wrapperRef}>
      <div
        className={classNames({
          [styles.burger]: true,
          [styles.burgerActive]: showBurger,
        })}
        onClick={showBurgerHandler}
      >
        <span></span>
      </div>
      <div
        className={classNames({
          [styles.burgerContent]: true,
          [styles.active]: showBurger,
        })}
      >
        <Link to={ACCOUNT_PROFILE} className={styles.burgerContentItem}>
          Account Profile
        </Link>

        <Link to={BRAND_KIT_URL} className={styles.burgerContentItem}>
          Brand Kit
        </Link>

        <Link to={NEW_GRADUATION} className={styles.burgerContentItem}>
          New Graduation
        </Link>

        <Link to={GRADUATION_LIBRARY} className={styles.burgerContentItem}>
          Graduation Library
        </Link>
      </div>
    </div>
  );
};

export default Burger;
