import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import * as urls from './routePaths';
import AppLayout from './components/AppLayout';
import ProtectedArea from './components/ProtectedArea';
import AccountProfile from './pages/AccountProfile';
import BrandKit from './pages/BrandKit';
import NewGraduation from './pages/NewGraduation';
import GraduationLibrary from './pages/GraduationLibrary';
import LogIn from './pages/LogIn';
import SignUp from './pages/SignUp';
import Home from './pages/Home';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button onClick={() => loginWithRedirect()}>Log In</button>;
};

// const LogoutButton = () => {
//   const { logout } = useAuth0();

//   return (
//     <button
//       onClick={() =>
//         logout({ logoutParams: { returnTo: window.location.origin } })
//       }
//     >
//       Log Out
//     </button>
//   );
// };

function App() {
  // const { isAuthenticated, isLoading } = useAuth0();
  return (
    <Routes>
      <Route path={urls.MAIN_URL} element={<Home />} />
      <Route
        path={urls.LOGIN}
        element={
          //<ProtectedArea>
          <LogIn />
          //</ProtectedArea>
        }
      />
      <Route
        path={urls.SINGUP}
        element={
          //<ProtectedArea>
          <SignUp />
          //</ProtectedArea>
        }
      />
      <Route element={<AppLayout />}>
        <Route
          path={urls.ACCOUNT_PROFILE}
          element={
            <ProtectedArea>
              <AccountProfile />
            </ProtectedArea>
          }
        />
        <Route
          path={urls.BRAND_KIT_URL}
          element={
            <ProtectedArea>
              <BrandKit />
            </ProtectedArea>
          }
        />
        {/* <Route
          path={'/sign-in'}
          element={
            //<ProtectedArea>
            <SignInForm />
            //</ProtectedArea>
          }
        />
        <Route
          path={'/sign-up'}
          element={
            //<ProtectedArea>
            <SignUpForm />
            //</ProtectedArea>
          }
        /> */}
          <Route
              path={urls.NEW_GRADUATION}
              element={
                  <ProtectedArea>
                      <NewGraduation />
                  </ProtectedArea>
              }
          />
          <Route
              path={urls.EDIT_GRADUATION}
              element={
                  <ProtectedArea>
                      <NewGraduation />
                  </ProtectedArea>
              }
          />
        <Route
          path={urls.GRADUATION_LIBRARY}
          element={
            <ProtectedArea>
              <GraduationLibrary />
            </ProtectedArea>
          }
        />
      </Route>
      <Route path="/" element={<Navigate to={'/login'} />} />
    </Routes>
  );
}

export default App;
