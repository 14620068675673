import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ProvideAuth} from "./hooks/useAuth";
import {BrowserRouter} from 'react-router-dom';
import { env } from './env'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

console.log('data', env);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ProvideAuth>
                <App/>
            </ProvideAuth>
        </BrowserRouter>
    </React.StrictMode>,
);
;
