import { Link, NavLink } from 'react-router-dom';
import styles from './Siderbar.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ACCOUNT_PROFILE,
  BRAND_KIT_URL,
  NEW_GRADUATION,
  GRADUATION_LIBRARY,
} from '../../routePaths';
import menuOpen from '../../assets/images/menu_open.svg';
import menuClosed from '../../assets/images/bars-solid.svg';
import logo from '../../assets/images/logo3.svg';
import logoutIcon from '../../assets/images/logout.svg';
import BrandKitIcon from '../../assets/images/Bolt.svg';
import AccountProfileIcon from '../../assets/images/info_outline.svg';
import NewGraduationIcon from '../../assets/images/receipt.svg';
import GraduationLibraryIcon from '../../assets/images/play.svg';
import APIService from '../../network/APIService';

interface SidebarProps {
  toggleSidebar(): void;
  isOpen: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ toggleSidebar, isOpen }) => {
  const { logout, user } = useAuth0();
  const email = user?.email;
  const userImage = localStorage.getItem('userImage');

  const beforeAtSign = email?.slice(0, email.indexOf('@') + 1);
  const afterAtSign = email?.slice(email.indexOf('@') + 1);

  const iconKey = isOpen ? 'menuCloseIcon' : 'menuOpenIcon';

  const handleLogout = async () => {
    try {
      const response = await APIService.logout();
      if (response) {
        localStorage.removeItem('token');
        localStorage.removeItem('userImage');
        window.location.replace('/login');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <aside className={`${styles.sidebar} ${isOpen ? styles.open : styles.closed}`}>
      <div>
        <div className={isOpen ? styles.sidebarHeaderOpen : styles.sidebarHeaderClosed}>
          <Link to="/" className={styles.logo}>
            <img
              src={isOpen ? logo : logo}
              alt="logo"
              className={isOpen ? styles.logoImage : styles.logoOnly}
            />
          </Link>
          <img
            key={iconKey}
            src={isOpen ? menuOpen : menuClosed}
            className={`${styles.menuIconButton}`}
            alt={isOpen ? 'menu open' : 'menu close'}
            onClick={toggleSidebar}
          />
        </div>
      </div>
      <div className={styles.profileInfo}>
        <div className={styles.profileInfoImg}>
          <img
            // src="https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png"
            src={
              userImage ??
              'https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png'
            }
            alt="profile"
          />
        </div>
        <div className={styles.profileInfoTexts}>
          <div className={styles.profileInfoEmail}>
            <button className={styles.logout} onClick={handleLogout}>
              <div className={isOpen ? styles.email : styles.emailClosed}>
                <p>{beforeAtSign}</p>
                <p>{afterAtSign}</p>
              </div>
              <img
                key={iconKey}
                src={logoutIcon}
                className={`${styles.logoutIcon} ${isOpen ? '' : styles.logoutIconClosed}`}
                alt="logout icon"
              />
            </button>
          </div>
        </div>
      </div>
      <div className={styles.listWrapper}>
        <div className={styles.list}>
          <NavLink
            to={ACCOUNT_PROFILE}
            className={`${styles.item} ${isOpen ? '' : styles.centered}`}
          >
            <img src={AccountProfileIcon} alt="account profile" />
            {isOpen && <span>Account Profile</span>}
          </NavLink>

          <NavLink to={BRAND_KIT_URL} className={`${styles.item} ${isOpen ? '' : styles.centered}`}>
            <img src={BrandKitIcon} alt="brand kit" />
            {isOpen && <span>Brand Kit</span>}
          </NavLink>

          <NavLink
            to={NEW_GRADUATION}
            className={`${styles.item} ${isOpen ? '' : styles.centered}`}
          >
            <img src={NewGraduationIcon} alt="new graduation" />
            {isOpen && <span>New Graduation</span>}
          </NavLink>

          <NavLink
            to={GRADUATION_LIBRARY}
            className={`${styles.item} ${isOpen ? '' : styles.centered}`}
          >
            <img src={GraduationLibraryIcon} alt="graduation library" />
            {isOpen && <span>Graduation Library</span>}
          </NavLink>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
